@import "../@core/scss/base/bootstrap-extended/include";
@import "../scss/spin.scss";

.loader-spinning {
  svg {
    transition: all 0.2s ease;
    @include spin();
  }
}

.dark-layout {
  .loader-spinning {
    color: $white;
  }
}
