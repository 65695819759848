.attachment-card.card.attachment-card-placeholder {
  .card-header {
    .card-title {
      flex-grow: 1;

      .text-row {
        display: flex;
        width: 65%;
        font-size: calc(1.45 * 1.285rem);
      }
    }
  }
}
