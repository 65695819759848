@import "../../Entity/EntityCard/EntityCardHeader.scss";
@import "../../../scss/width.scss";
@import "../../../@core/scss/base/components/include";

$action-color: #6e6b7b;

.guest-card-header.card-body.entity-card-header {
  .entity-card-header-toggler-wrapper {
    .entity-card-header-content {
      .avatar {
        .guest-image {
          width: 6rem;
          height: 6rem;

          border: 0.35rem solid #6e6b7b;
          border-radius: 50%;

          &.is-currently-staying {
            border-color: $blue;
          }
        }
      }

      .guest-card-header-total-bookings-nights {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        white-space: nowrap;

        svg,
        .svg {
          margin-left: 0.125rem;
          margin-right: 0.4rem;
          margin-top: 1px;

          &:last-child {
            margin-right: 0;
            margin-top: -1px;
          }
        }
      }

      .entity-card-header-info {
        @include width(calc(100% - 7rem));

        .entity-card-header-data-row-wrapper {
          .row {
            row-gap: 0.35rem;
          }
        }
      }
    }
  }
}
