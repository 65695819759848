@import "../../../scss/width.scss";
@import "../../../@core/scss/base/bootstrap-extended/include";
@import "../../../@core/scss/base/components/include";

$action-color: #6e6b7b;

.card-body.entity-card-header {
  transition: padding 0.2s ease;

  padding: 0.35rem;
  @media (min-width: 430px) {
    padding: 1rem;
  }

  .entity-card-header-toggler-wrapper {
    display: flex;
    flex-direction: row;
    @include width(100%);

    height: 100%;

    .entity-card-header-content {
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;

      height: 100%;

      @include width(calc(100%));

      .entity-card-header-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        row-gap: 0.35rem;

        font-size: 1rem;

        @include width(100%);

        .entity-card-header-actions {
          margin-left: auto;

          .entity-card-header-actions-button-container {
            display: inline-block;
            margin-left: 0.25rem;

            .btn {
              color: $action-color;
              border-radius: 50%;
              width: 36px;
              height: 36px;
              padding: 0;
              background-color: $body-bg;

              &.btn-outline-action {
                border: 2px solid $action-color !important;
              }

              &:hover,
              &:active,
              &:focus {
                &:not(:disabled) {
                  background-color: $white;

                  &.btn-outline-action {
                    border-color: $primary;
                  }
                }
              }
            }
          }
        }

        .entity-card-header-data-row-wrapper {
          display: flex;
          flex-direction: column;
          row-gap: 0.35rem;
          flex-grow: 1;
          max-width: 100%;

          justify-content: flex-start;
        }

        .entity-card-header-data-row {
          display: flex;
          flex-direction: row;
          column-gap: 0.35rem;
          align-items: center;

          .interactive {
            font-weight: 600;
          }
        }

        .entity-card-header-name {
          font-size: 1.5rem;

          display: inline-flex;
          align-items: center;
        }

        .interactive {
          transition: color 0.12s;
          color: $body-color;
          position: relative;
          text-decoration: 0.1rem underline;
          text-decoration-skip-ink: none;

          a {
            transition: color 0.12s;
          }

          &:hover {
            color: $primary;
            cursor: pointer;
          }
        }

        .filled-orange {
          fill: $orange;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

@media (min-width: 430px) {
  .card-body.entity-card-header {
    .entity-card-header-toggler-wrapper {
      .entity-card-header-content {
        .entity-card-header-info {
          .entity-card-header-actions {
            .entity-card-header-actions-button-container {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .card-body.entity-card-header {
    .entity-card-header-toggler-wrapper {
      flex-direction: column;

      .entity-card-header-content {
        @include width(100%);
      }
    }
  }
}

.ellipsis-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

body.dark-layout {
  .card.entity-card:not(.entity-card-placeholder) {
    .card-body.entity-card-header {
      .entity-card-header-toggler-wrapper {
        .entity-card-header-content {
          .entity-card-header-info {
            .entity-card-header-actions {
              .entity-card-header-actions-button-container {
                .btn {
                  transition: background-color 0.12s
                    cubic-bezier(0.25, 1, 0.5, 1);

                  &.disabled,
                  &:disabled {
                    opacity: 0.35;
                  }

                  color: $primary;
                  background-color: $light;

                  &.btn-outline-action {
                    border: 2px solid $white !important;
                  }

                  &:hover,
                  &:active,
                  &:focus {
                    &:not(:disabled) {
                      background-color: $white;
                      color: $black;

                      &.btn-outline-action {
                        border-color: $white;
                      }
                    }
                  }
                }
              }
            }

            a {
              transition: color 0.12s;
              color: $light;
              text-decoration: underline;

              &:hover {
                color: $white;
              }
            }

            .interactive {
              transition: color 0.12s;
              color: $light;
              position: relative;
              text-decoration: 0.1rem underline;
              text-decoration-skip-ink: none;

              a {
                color: $light;

                &:hover {
                  color: $white;
                }
              }

              &:hover {
                color: $white;
                cursor: pointer;
              }
            }

            .filled-orange {
              stroke: $theme-dark-body-bg;
              stroke-width: 1px;
            }
          }
        }
      }
    }
  }
}
