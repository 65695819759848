@mixin timeline-placeholder {
  .timeline.timeline-placeholder {
    .timeline-item {
      .timeline-point {
        border: none;
      }

      &:last-of-type::after {
        display: none;
      }
    }
  }
}

@include timeline-placeholder;

.dark-layout {
  @include timeline-placeholder;
}
