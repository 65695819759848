@import "../../DateRange.scss";
@import "./ReservationCardHeader.scss";
@import "./ReservationCard.scss";

.reservation-card-placeholder.reservation-card.entity-card {
  .reservation-badge-wrapper {
    height: 36.4px;
    width: 125px;

    .reservation-badge.badge {
      /** Preventing from hiding :empty badges */
      display: block !important;
    }
  }

  .icon-text-wrapper {
    height: 20px;
    width: 6px;

    &.adult-icon-text-wrapper + .svg {
      height: 28px;
      width: 20px;
    }
  }

  .guests-children {
    .svg {
      height: 24px;
      width: 18px;
    }
  }

  .svg.bed-icon {
    height: 28px;
    width: 28px;

    & + div {
      height: 20px;
      width: 10px;
    }
  }

  .reservation-card-header-main-guest-wrapper span div {
    height: 34px;
    max-width: 75%;
  }

  .date-range-placeholder.date-range {
    margin-top: 1.5rem;
  }
}
