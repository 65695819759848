@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include";

.entity-card {
  transition: box-shadow 0.2s ease-in-out;

  .collapse-toggler {
    display: flex;
    align-items: center;
    text-align: center;

    margin-left: 1rem;

    transition: opacity 0.15s;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .note-card-body {
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .entity-card {
    .collapse-toggler {
      margin-left: 0rem;
      margin-top: 1rem;

      justify-content: center;

      &.without-notes {
        display: none;
      }
    }
  }
}

.dark-layout {
  .collapse-toggler {
    transition: color 0.2s ease;
    color: $light;

    &:hover {
      color: $white;
    }
  }

  .card-body {
    .action-icons {
      color: $theme-dark-primary !important;
    }
  }

  .card-body {
    .action-icons {
      color: $theme-dark-primary !important;
    }
  }

  .card-body {
    .action-icons {
      color: $theme-dark-primary !important;
    }
  }
}
