@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include";

.attachment {
  display: flex;
  margin-bottom: 0.75rem;

  svg,
  .svg {
    margin-right: 0.75rem;
    min-height: 24px;
    width: 24px;
  }

  a.text-underline-hover:hover {
    text-decoration: underline;
  }

  a:not(.text-underline-hover) {
    cursor: not-allowed;
    color: lighten($body-color, 25%) !important;
  }
}

.ellipsis-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.dark-layout {
  .attachment {
    a.text-underline-hover {
      transition: color 0.12s;
      color: $light;

      &:hover {
        color: $white;
      }
    }

    a:not(.text-underline-hover) {
      color: $theme-dark-primary;
    }
  }
}
