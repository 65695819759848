@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

[dir="ltr"],
[dir="rtl"] {
  form.chat-app-form {
    textarea.form-control {
      height: 38px;
      padding: 0.438rem 1rem !important;
      resize: none;
    }

    button.send:not(:last-child) {
      padding-right: 0.75rem;
    }

    .dropdown-toggle {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &::after {
        left: 0px;
        right: 0px;
      }
    }

    .property-templates {
      margin: 5px 0;
    }

    .property-submenu {
      transform: translate3d(-210px, 5px, 0px) !important;
      min-width: 215px;
    }
  }

  .new-message-toast-wrapper {
    position: absolute;
    bottom: 10rem;
    width: 16rem;
    left: calc(50% - 8rem);

    .toast-header {
      color: #6e6b7b;
      border-radius: $card-border-radius !important;

      .new-message-notification {
        cursor: pointer;
      }
    }
  }
}
