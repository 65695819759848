@import "../../../@core/scss/base/bootstrap-extended/variables";

@import "../../../scss/h-100.scss";
@import "../../../scss/w-100.scss";
@import "../../../scss/width.scss";

.droppable-card-wrapper {
  @include h-100;
  @include w-100;

  .form-group {
    display: flex;
    flex-direction: column;

    margin-bottom: 0;

    @include h-100;

    label {
      display: flex;
      flex-shrink: 1;
    }

    .droppable-card.card.card-body {
      display: flex;
      flex-grow: 1;

      overflow-y: auto;
      border: 1px solid $border-color;
      row-gap: 0.5rem;
      padding: 0.5rem;
      margin-bottom: 0;

      max-height: calc(100% - 0.2857rem - 0.857rem);
    }
  }

  &.draggable-over {
    .droppable-card.card.card-body {
      background-color: $body-bg;
    }
  }
}
