@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include";

.attachment-card.card {
  transition: color 0.12s cubic-bezier(0.25, 1, 0.5, 1),
    background-color 0.12s cubic-bezier(0.25, 1, 0.5, 1);
  outline: 2px dashed $white;

  &:hover:not(.card-placeholder) {
    background-color: theme-color-level(secondary, -11);
    outline-color: theme-color-level(secondary, -11);
  }

  .card-header {
    a.add:hover {
      text-decoration: underline;
    }
  }

  .card-body {
    padding-bottom: 0.9rem;
  }

  input[type="file"] {
    display: none;
  }

  .attachments-droppable {
    outline: 2px dashed $secondary;
    border-radius: 0.5rem;
    padding: 2rem !important;
    text-align: center;
    color: lighten($body-color, 25%) !important;
    cursor: pointer;
  }

  &.drag-over-empty {
    .attachments-droppable {
      background-color: #f2f0f7;
    }
  }

  &.drag-over-active {
    outline-color: $secondary;
    outline-style: dashed;
    background-color: #f2f0f7;
  }
}

.dark-layout {
  .attachment-card.card {
    outline: 2px solid $theme-dark-card-bg;
    background-color: $theme-dark-card-bg;

    &:hover:not(.card-placeholder) {
      background-color: $theme-dark-widget-bg;
      outline-color: $theme-dark-widget-bg;

      .card-header {
        .card-title,
        a.add {
          color: $menu-dark-color;
        }
      }
    }

    &.drag-over-empty {
      .attachments-droppable {
        background-color: $theme-dark-body-bg !important;
      }
    }

    &.drag-over-active {
      outline: 2px dashed $theme-dark-text-muted-color !important;
      background-color: $theme-dark-widget-bg;
    }

    .card-header {
      a.add {
        transition: color 0.12s;
        color: $light;

        &:hover {
          color: $white;
        }
      }
    }
  }
}
