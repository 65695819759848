@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include";

.note-body {
  transition: border 0.12s cubic-bezier(0.25, 1, 0.5, 1),
    color 0.12s cubic-bezier(0.25, 1, 0.5, 1), padding 0.2s ease,
    margin 0.2s ease;

  position: relative;

  border-left: 2px solid $border-color;
  padding: 0;
  padding-left: 1rem;

  p {
    margin-bottom: 0.25rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    border-left: 2px solid $light;
    color: $black;
  }
}

@include media-breakpoint-up(md) {
  .note-body {
    padding: 0 0rem 0 3rem;
    margin-left: 0.75rem;
    margin-right: 1.25rem;

    p {
      margin-bottom: 0.5rem;
    }
  }
}

.dark-layout {
  .note-body {
    border-left: 2px solid $theme-dark-border-color;

    &:hover {
      border-left: 2px solid $light;
      color: $menu-dark-color;
    }
  }
}
