// ================================================================================================
//   File Name: app-chat-area.scss
//   Description: SCC file for chat area application page.
//   ----------------------------------------------------------------------------------------------
//   Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import "../bootstrap-extended/include"; // Bootstrap includes
@import "../components/include"; // Components includes

@import "../core/colors/palette-variables";

@import "bootstrap/scss/mixins/_gradients";
@import "bootstrap/scss/mixins/gradients";

//Variables
$chat-image-back-color: #f2f0f7;
$chat-head-footer-height: 65px;
$chat-widget-head-footer-height: 56px;
$chat-widget-height: 390px;

// User chat window css
.chat-app-window {
  position: relative;

  // Maximize icon
  &:hover {
    .maximize-chat-btn {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
  .maximize-chat-btn {
    opacity: 0.5;
    transition: opacity 0.35s ease;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    right: 0;
    margin: 0.5rem;
    .badge {
      width: 25px;
      height: 25px;
    }
  }

  // User chats
  .user-chats {
    background-color: $chat-image-back-color;
    padding: 1rem;
    position: relative;
    height: calc(
      100% - #{$chat-head-footer-height} - #{$chat-head-footer-height}
    );
    .avatar {
      img {
        border: 2px solid $white;
      }
    }
    &.empty-chat-msg {
      ::after {
        content: "no messages to show";
        color: $text-muted;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.1rem;
      }
    }
  }
  .active-chat {
    height: inherit;
    .chat-header {
      display: flex;
      justify-content: space-between;
      height: $chat-head-footer-height;
      background-color: $white;
      padding: 0 1rem;
      border-bottom: 1px solid $border-color;
    }
  }

  // Chat area css
  .chats {
    .chat {
      &:first-child {
        margin-top: 1.5rem;
      }
      &:last-child {
        .chat-message-footer {
          margin-bottom: 0;
        }
        .chat-content {
          margin: 0 1rem 5px 0;
        }
      }
    }
    .chat-avatar {
      float: right;
    }
    .chat-message-footer {
      margin-bottom: 30px;
      height: 25px;
      display: flex;
      align-items: center;
    }
    .chat-body {
      display: block;
      margin: 10px 30px 0 0;
      overflow: hidden;
      .chat-content {
        float: right;
        padding: 0.7rem 1rem;
        margin: 0 1rem 35px 0;
        clear: both;
        color: $white;
        // position: relative;
        @include gradient-directional(
          map-get($primary-color, "base"),
          map-get($primary-color, "lighten-2"),
          80deg
        );
        border-radius: $border-radius;
        box-shadow: 0 4px 8px 0 rgba($black, 0.12);
        max-width: 75%;
        p {
          margin: 0;
        }
        &::before {
          content: attr(data-timestamp);
          position: absolute;
          width: 100%;
          left: 0;
          text-align: center;
          color: $body-color;
          transform: translateY(-32px);
          font-size: 10px;
        }
      }
    }
    // Chat area left css
    .chat-left {
      .chat-avatar {
        float: left;
      }
      .chat-body {
        .chat-content {
          float: left;
          margin: 0 0 5px 1rem;
          color: $body-color;
          background: none;
          background-color: lighten($white, 18%);
        }
      }
    }
  }
  // Chat form of user area
  .chat-app-form {
    min-height: $chat-head-footer-height;
    padding: 1rem;
    background-color: $white;
    display: flex;
    align-items: center;
  }
}

// Chat Widget
.chat-widget {
  .card-header {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .chat-app-window {
    // User chats
    .user-chats {
      height: $chat-widget-height;
    }
    .chat-app-form {
      border-top: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      height: $chat-widget-head-footer-height;
      .input-group-text,
      .message {
        border: 0;
        padding-left: 0;
      }
      .input-group:not(.bootstrap-touchspin):focus-within {
        box-shadow: none;
      }
    }
  }
}

.chat-tabs-wrapper {
  width: 100%;
  height: 30px;
  // overflow-x: auto;
  display: flex;
  .chat-tab {
    height: 35px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    place-items: center;
    padding: 5px 10px;
    gap: 8px;
    cursor: pointer;
    background-color: $white;
    color: $text-muted;
    border-top-right-radius: 0.428rem;
    border-top-left-radius: 0.428rem;
    &:not(:first-child) {
      margin-left: 12px;
    }
    &.active-tab {
      color: $gray-900;
      background-color: $chat-image-back-color;
    }

    &:not(.active-tab) {
      &.new-message-tab {
        animation: red-color-pulse 1.5s ease infinite;
        .guest-image {
          border: 2px solid $red;
          animation: red-color-pulse 1.5s ease infinite;
        }
      }
    }
  }
}

@keyframes red-color-pulse {
  0% {
    color: $text-muted;
    border-color: transparent;
  }
  50% {
    color: $red;
    border-color: $red;
  }
  100% {
    color: $text-muted;
    border-color: transparent;
  }
}

.form-send-message {
  :disabled {
    cursor: not-allowed;
  }
}

.read-by-badge {
  cursor: pointer;
  font-size: 10px !important;
  line-height: 8px !important;
  border: 2px solid #fff;
  height: 20px;
  position: relative;
  top: 0px;
}

.chat-read-by-tooltip {
  .tooltip {
    .tooltip-inner {
      max-width: revert;
      > div:not(:last-child) {
        margin-bottom: 2px;
      }
      b,
      span {
        margin: 0 2px;
      }
      span {
        opacity: 0.7;
      }
    }
  }
}

.adjust-chat-position {
  top: -12px;
  .ps-chat-window {
    padding-top: 12px;
    height: 47px;
  }
}

// dark theme
.dark-layout {
  .read-by-badge {
    color: $text-muted !important;
    border-color: $theme-dark-border-color;
    background-color: $theme-dark-body-bg;
  }
  .chat-app-window {
    .start-chat-area {
      background-color: $theme-dark-card-bg;

      .start-chat-icon,
      .start-chat-text {
        background: $theme-dark-card-bg;
        color: $theme-dark-body-color;
      }
    }
    .start-chat-area,
    .user-chats {
      background-color: #1e232f;
    }
    .user-chats {
      .divider .divider-text {
        background: $theme-dark-card-bg;
        &:before,
        &:after {
          border-color: $theme-dark-border-color;
        }
      }
      .avatar img {
        border-color: $theme-dark-border-color;
      }
    }
    // Active conversation style
    .active-chat {
      .chat-left {
        .chat-content {
          background-color: $theme-dark-card-bg;
          p {
            color: $theme-dark-body-color;
          }
        }
      }

      .chat-content {
        p {
          color: $white;
        }
      }
      // Chat form
      .chat-header .dropdown-toggle {
        color: $theme-dark-body-color;
      }
      .chat-header,
      .chat-app-form {
        background-color: $theme-dark-card-bg;
        border-color: $theme-dark-border-color;
      }
    }
  }
  .chat-tabs-wrapper {
    .chat-tab {
      background-color: $theme-dark-card-bg;
      &.active-tab {
        background-color: #1e232f;
        color: $white;
      }
    }
  }
}
