.date-range-placeholder.date-range {
  .date-range-wrapper {
    &.date-start,
    &.date-end {
      .date-range-top,
      .date-range-bottom {
        height: 1rem;
        margin-bottom: 0.45rem;
      }

      .date-range-middle {
        height: 1.2rem;
        margin-bottom: 0.45rem;
      }
    }
  }

  .date-center {
    .range-middle {
      .range-nights {
        height: 2.5rem;

        > div {
          width: 1.5rem;
        }
      }
    }
  }
}
