@import "@src/@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "@src/@core/scss/base/components/include"; // Components includes

.chat-log-simple-placeholder.chat-app-window {
  .user-chats {
    background-color: #f2f0f7;
    height: 300px;

    .chats {
      .chat {
        .chat-avatar {
          width: 32px;
          height: 32px;
        }

        .chat-body {
          .chat-content {
            width: 80%;
            height: 30px;
            background-image: none;
            box-shadow: none;
          }

          .text-small {
            font-size: 16px;
            clear: both;

            .text-row {
              min-width: 130px;
              max-width: 60%;
            }
          }
        }
      }
    }
  }

  .chat-app-form {
    .input-group-merge {
      .form-control.text-row {
        font-size: 38px;
        border: none;
      }
    }

    .btn-group {
      .text-row.send.btn {
        font-size: 38px;
        border: none;
        border-radius: 0.358rem;
        width: 60px;
        cursor: default;
      }
    }
  }
}

[dir] .dark-layout {
  .chat-log-simple-placeholder.chat-app-window {
    .user-chats {
      background-color: $theme-dark-card-bg;
    }
  }
}
