@import "../../../scss/h-100.scss";
@import "../../../scss/width.scss";

$reservation-list-report-toggle-buttons-column-width: 30px;

.reservation-report {
  // overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @include h-100;
  @include width(100%);

  .report-column-selector-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 200px;
    height: calc(100% - 5.5rem);
    overflow: hidden;
  }

  .report-column-selector {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &.row {
      flex-direction: row;

      .column {
        padding-left: 1rem;
        padding-right: 1rem;
        @include h-100;

        &.droppable-card-column {
          @include width(
            calc(
              50% -
                (#{$reservation-list-report-toggle-buttons-column-width} / 2) -
                1rem
            )
          );
        }
      }

      .toggle-buttons-column {
        padding-top: 21.4px;

        display: flex;
        flex-direction: column;
        row-gap: 0.25rem;
        align-items: center;
        align-content: center;
        justify-content: center;

        @include width(
          calc(#{$reservation-list-report-toggle-buttons-column-width} + 2rem)
        );
      }
    }
  }
}
