.attachment.attachment-placeholder {
  svg,
  .svg {
    .rect-shape {
      display: block;
      min-height: 24px !important;
      width: 24px !important;
    }
  }

  .ellipsis.small {
    display: flex;
    flex-grow: 1;
    margin-bottom: 0;

    .text-row {
      display: flex;
      font-size: calc(1.45 * 0.857rem);
      width: 75%;
    }
  }
}
