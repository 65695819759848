.show-loading-animation {
  .pretty-cell-placeholder,
  &.pretty-cell-placeholder {
    animation: react-placeholder-pulse 1.5s infinite;
  }
}

@keyframes react-placeholder-pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
