@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include";

.badge.reservation-badge {
  border-radius: 0.428rem;
  padding-left: 1rem;
  padding-right: 1rem;
  align-self: flex-start;

  font-size: 2rem;
  justify-content: center;
  column-gap: 0.2rem;
  align-items: center;

  span {
    align-self: center;
  }

  .weak-text {
    font-size: 0.65em;
    opacity: 0.65;
    transition: 0.3s ease;
  }
}

.dark-layout {
  .badge.reservation-badge {
    &:hover {
      .weak-text {
        opacity: 0.85;
      }
    }

    .weak-text {
      transition: opacity 0.12s cubic-bezier(0.25, 1, 0.5, 1);
    }

    color: $white;
  }
}
