@import "../../Entity/EntityCard/EntityCard.scss";

body {
  &.dark-layout {
    .date-center {
      .range-nights {
        background-color: $theme-dark-card-bg;
      }
    }

    .bold {
      color: $theme-dark-purple;
    }

    .interactive {
      color: $theme-dark-primary;

      &:hover {
        color: $theme-dark-primary;
      }
    }

    .spacer-decorator {
      &::after {
        content: "";
        height: 100px;
        position: absolute;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
        left: clamp(0, 4vw, -2rem);
        background-color: $theme-dark-border-color;
      }
    }
  }

  &:not(.dark-layout) {
    .date-center {
      .range-nights {
        background-color: #fff;
      }
    }
    .spacer-decorator {
      &::after {
        content: "";
        height: 100px;
        position: absolute;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
        left: clamp(0, 4vw, -2rem);
        background-color: $border-color;
      }
    }
  }
}

/** Modals */

.modal-dialog-centered.modal-dialog-scrollable.modal-dialog-centered-scrollable {
  .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
}
