@import "../../../base/bootstrap-extended/include";
@import "../../../base/components/include";

.uppy-Root {
  border-radius: $border-radius;
  border-color: $primary;

  &.uppy-DragDrop-container:focus {
    box-shadow: none;
  }

  .uppy-DragDrop-arrow {
    fill: $primary;
  }

  .uppy-DragDrop-label {
    color: $body-color;
  }

  .uppy-DragDrop-browse {
    color: $primary;
  }
}

.dark-layout {
  .uppy-Root {
    background-color: $theme-dark-body-bg;
  }
}
