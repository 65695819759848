@import "@src/scss/spin.scss";
@import "@src/scss/width.scss";

.reservation-precheckin-modal {
  .btn.btn-confirm {
    svg {
      transition: all 0.2s ease;

      @include width(0);
      @include spin();
      margin-right: 0;
    }

    &.btn-loading {
      svg {
        width: auto;
        max-width: none;
        margin-right: 0.5rem;
      }
    }
  }
}
