@import "../../@core/scss/base/core/colors/palette-variables.scss";
@import "@src/@core/scss/base/components/include";

@mixin property-statistic-card-icon-container($opacity) {
  .icon-container {
    @each $color_name, $color in $colors {
      @if (
        $color_name !=
          "light" and
          $color_name !=
          "black" and
          $color_name !=
          "white" and
          $color_name !=
          "dark"
      ) {
        &.bg-light-#{$color_name} {
          background: rgba(map-get($color, "base"), $opacity) !important;
        }
      }
    }
  }
}

.property-statistics-today {
  .stats-card.card {
    &.cursor-pointer {
      transition: background-color 0.12s cubic-bezier(0.25, 1, 0.5, 1),
        color 0.12s cubic-bezier(0.25, 1, 0.5, 1);

      &:hover {
        background-color: theme-color-level(secondary, -11);

        .card-body {
          .icon-section {
            @include property-statistic-card-icon-container(0.18);
          }
        }
      }
    }
  }

  .property-statistics-title {
    .extension-title {
      display: inline;
    }

    svg {
      float: right;
    }
  }
}

.dark-layout {
  .property-statistics-today {
    .stats-card.card {
      .card-body {
        .icon-section {
          @include property-statistic-card-icon-container(0.27);
        }
      }

      &.cursor-pointer {
        &:hover {
          background-color: $theme-dark-widget-bg;

          .card-body {
            .icon-section {
              @include property-statistic-card-icon-container(0.32);
            }

            .title-section {
              h2 {
                color: $white;
              }

              p {
                color: $menu-dark-color;
              }
            }
          }
        }
      }
    }
  }
}
