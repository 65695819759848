@import "../../@core/scss/base/core/colors/palette-variables.scss";
@import "@src/@core/scss/base/components/include";

.login-form {
  .btn.login-form-button-submit.btn-primary {
    transition: background-color 0.12s cubic-bezier(0.25, 1, 0.5, 1),
      color 0.12s cubic-bezier(0.25, 1, 0.5, 1);
  }
}

.dark-layout {
  .login-form {
    .btn.login-form-button-submit.btn-primary {
      &:hover {
        background-color: map-get($primary-color, "darken-1") !important;
      }
    }
  }
}
