.reservation-confirmation-number-pretty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 1rem;

  .text-weak {
    font-size: 0.8rem;
    opacity: 0.85;
    transition: 0.3s ease;
  }
}
