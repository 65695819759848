@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include";
@import "../../@core/scss/base/core/colors/palette-variables.scss";

.btn.google-login-button {
  transition: background-color 0.12s cubic-bezier(0.25, 1, 0.5, 1),
    color 0.12s cubic-bezier(0.25, 1, 0.5, 1),
    border 0.12s cubic-bezier(0.25, 1, 0.5, 1);

  border: 1px solid map-get($dark-color, "darken-3") !important;
  background-color: $white !important;
  color: map-get($dark-color, "darken-3") !important;

  display: flex;
  padding-left: calc(1.5rem - 11px);
  padding-right: calc(1.5rem + 11px);
  width: 100%;

  &:hover,
  &:focus {
    background-color: $white !important;
    border: 1px solid black !important;
    color: black !important;
  }

  .google-login-icon-wrapper {
    margin-top: "-2px";
    margin-bottom: "-2px";
  }

  .google-login-text-wrapper {
    flex-grow: 1;
    padding-top: 1px;
  }
}

.dark-layout {
  .btn.google-login-button {
    background-color: $menu-dark-color !important;

    color: $black;

    &:hover {
      background-color: $white !important;
    }
  }
}
