@import "@src/@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "@src/@core/scss/base/components/include"; // Components includes

@mixin entity-card-hover-bg($background-color) {
  &:hover {
    background-color: $background-color;

    .date-center .range-middle .range-nights {
      background-color: $background-color;
    }
  }
}

.card.entity-card:not(.entity-card-placeholder) {
  transition: background-color 0.12s cubic-bezier(0.25, 1, 0.5, 1);

  @include entity-card-hover-bg(theme-color-level(secondary, -11));
}

.dark-layout {
  .card.entity-card:not(.entity-card-placeholder) {
    @include entity-card-hover-bg($theme-dark-widget-bg);
  }
}
