@import "../../../base/bootstrap-extended/include";
@import "../../../base/components/include";

.dark-layout {
  .app-user-list {
    header {
      background-color: $theme-dark-card-bg;
    }
  }

  .badge {
    color: $theme-dark-card-bg;
  }

  .react-dataTable {
    .hlidmt {
      background-color: $theme-dark-card-bg;
    }
    .khKjDK {
      background-color: $theme-dark-card-bg;
      color: $theme-dark-body-color;
    }

    .rdt_Table {
      background: $theme-dark-table-header-bg;
      color: $theme-dark-body-color;
    }
    .rdt_TableHead {
      .rdt_TableHeadRow {
        background-color: $theme-dark-card-bg;
        border-color: $theme-dark-border-color;
        .rdt_TableCol {
          color: $theme-dark-body-color;
          div[role="button"] {
            color: $theme-dark-body-color;
          }
        }
        .rdt_TableCol_Sortable,
        .rdt_TableCol_Sortable:hover {
          color: $theme-dark-headings-color;
          svg {
            stroke: $theme-dark-headings-color;
          }
        }
      }
    }
    .rdt_TableBody {
      .expandable-content {
        background-color: $theme-dark-table-bg;
        color: $theme-dark-body-color;
      }
      .rdt_TableRow {
        background-color: $theme-dark-table-bg;
        color: $theme-dark-body-color;
        border-color: $theme-dark-border-color;
        a {
          color: $theme-dark-body-color;

        }
        .rdt_TableCell {
          color: $theme-dark-body-color;
        }
        button {
          &:hover:not(:disabled) {
            background-color: $theme-dark-body-bg;
          }
          svg {
            color: $theme-dark-body-color;
          }
        }
      }
    }
    &.rdt_Hoverable {
      .rdt_TableRow {
        &:hover {
          background-color: rgba($white, 0.05);
        }
      }
    }
    .rdt_Pagination {
      background-color: $theme-dark-table-bg;
      color: $theme-dark-body-color;

      svg {
        fill: $theme-dark-body-color;
      }
    }
  }

  .rdt_Pagination {
    color: $theme-dark-body-color;
    background-color: $theme-dark-card-bg;
    button {
      svg {
        fill: $theme-dark-body-color;
      }
      &[disabled] svg {
        fill: $theme-dark-text-muted-color;
      }
    }
  }
}

.react-dataTable {
  &.rdt_Hoverable {
    .rdt_TableBody {
      .rdt_TableRow {
        cursor: pointer;

        &:hover {
          background-color: rgba($primary, 0.05);
        }
      }
    }
  }
}
