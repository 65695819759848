@import "../../../@core/scss/base/bootstrap-extended/variables";
@import "@src/scss/w-100.scss";

$box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05);
$box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
$box-shadow: $box-shadow-border, $box-shadow-common;
$focused-outline-color: #4c9ffe;

@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: $box-shadow;
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: $box-shadow-border, -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.reservation-report-column-item-wrapper {
  // touch-action: none;
  list-style: none;
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;

  &.fadeIn {
    animation: fadeIn 500ms ease;
  }

  &.dragOverlay {
    --scale: 1.01;
    --box-shadow: $box-shadow;
    --box-shadow-picked-up: $box-shadow-border,
      -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
    z-index: 2000;
  }

  .reservation-report-column-item {
    // touch-action: none;
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    @include w-100;
    padding: 0;
    background-color: $white;
    box-shadow: $box-shadow;
    outline: none;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    list-style: none;
    transform-origin: 50% 50%;

    -webkit-tap-highlight-color: transparent;

    color: #333;
    font-weight: 400;
    font-size: 1rem;
    white-space: nowrap;

    transform: scale(var(--scale, 1));
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

    &.clickable {
      cursor: pointer;
    }

    &:focus-visible {
      box-shadow: 0 0px 4px 1px $focused-outline-color, $box-shadow;
    }

    &.dragging:not(.dragOverlay) {
      opacity: var(--dragging-opacity, 0.5);
      z-index: 1050;

      &:focus {
        box-shadow: $box-shadow;
      }
    }

    &.disabled {
      color: #999;
      background-color: #f1f1f1;
      &:focus {
        box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), $box-shadow;
      }
      cursor: not-allowed;
    }

    &.dragOverlay {
      cursor: inherit;
      //   box-shadow: $box-shadow;
      animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
      // animation: pop 200ms ease;
      transform: scale(var(--scale));
      // box-shadow: var(--box-shadow-picked-up);
      box-shadow: $box-shadow-border, -1px 0 15px 0 rgba(34, 33, 81, 0.01),
        0px 15px 15px 0 rgba(34, 33, 81, 0.25);
      opacity: 1;
    }

    &.color:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 100%;
      width: 3px;
      display: block;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      background-color: var(--color);
    }

    &.selected {
      background-color: $primary;
      color: $body-bg;
    }

    .reservation-report-column-item-content {
      display: flex;
      flex-grow: 1;
      padding-top: 0.25rem;
      padding-left: 0.5rem;
      padding-bottom: 0.25rem;
      padding-right: 0;
    }

    .reservation-report-column-item-actions {
      display: flex;
      align-self: flex-center;

      .handle.btn.btn-link {
        cursor: move !important;

        padding-top: 0.25rem;
        padding-left: 0.5rem;
        padding-bottom: 0.25rem;
        padding-right: 0.25;

        &:hover,
        &:focus {
          &:not(.disabled):not(:disabled) {
            background-color: transparent;
          }
        }
      }
    }
  }
}
.ellipsis-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
