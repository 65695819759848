@import "../../Entity/EntityCard/EntityCardHeader.scss";
@import "@src/@core/scss/base/components/include";

$action-color: #6e6b7b;

.card-body.reservation-card-header.entity-card-header {
  .entity-card-header-toggler-wrapper {
    .entity-card-header-content {
      @include width(100%);

      .entity-card-header-info {
        .reservation-card-header-guests-numbers {
          display: flex;
          column-gap: 0.2rem;
          grid-row: 2;
          grid-column: 1;
          margin-left: 0.35rem;
          font-size: 1rem;

          > div {
            display: flex;
            column-gap: 0.2rem;
            place-items: center;
          }

          .card-header-icon-wrapper {
            margin-left: 0.15rem;

            svg {
              &,
              .svg {
                margin-left: 0;
                width: 2rem;

                &.adult-icon {
                  margin-left: -0.25rem;
                  margin-right: -0.25rem;
                }
              }

              &.bed-icon {
                fill: $body-color;
                stroke: $body-color;
                stroke-width: 10px;
                margin-right: 0rem;
                margin-top: 2px;
                margin-left: 0.2rem;
              }
            }
          }

          .guests-children {
            svg,
            .svg {
              width: 1.3rem;
            }
          }
        }

        .reservation-card-header-main-guest-wrapper {
          font-size: 2rem;
        }
      }
    }
  }
}

@media (min-width: 430px) {
  .card-body.reservation-card-header.entity-card-header {
    .entity-card-header-toggler-wrapper {
      .entity-card-header-content {
        .entity-card-header-info {
          .reservation-card-header-guests-numbers {
            .card-header-icon-wrapper {
              margin-left: 0.5rem;

              svg,
              .svg {
                &.adult-icon {
                  margin-left: 0;
                  margin-right: 0;
                }

                &.bed-icon {
                  margin-right: 0.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .card-body.reservation-card-header.entity-card-header {
    .entity-card-header-toggler-wrapper {
      .entity-card-header-content {
        @include width(100%);
      }
    }
  }
}

.dark-layout {
  .card-body.reservation-card-header.entity-card-header {
    .entity-card-header-toggler-wrapper {
      .entity-card-header-content {
        .entity-card-header-info {
          .reservation-card-header-guests-numbers {
            .card-header-icon-wrapper {
              svg {
                &.bed-icon {
                  fill: $theme-dark-body-color;
                  stroke: $theme-dark-body-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
