@import "@src/@core/scss/base/bootstrap-extended/include"; // Bootstrap includes

.date-range {
  justify-content: flex-start;
  display: flex;
  place-items: center;
  column-gap: 0.7rem;
  position: relative;
  margin-top: 0.5rem;

  width: 100%;

  .date-range-wrapper {
    min-width: 130px;

    &.date-start {
      text-align: left;
    }

    &.date-end {
      text-align: right;
    }

    .date-range-middle {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }

  .date-center {
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    width: 100%;

    .range {
      right: 0;
      width: 0;
      border: 0rem solid $theme-dark-primary;
      border-width: clamp(0.4rem, 0.75vw, 0.5rem);
      border-radius: 100%;
    }

    .range-middle {
      margin: auto;

      .range-nights {
        position: relative;
        z-index: 2;
        padding: 0 0.5rem;
        font-size: 2.5rem;
        font-weight: 500;
      }

      .range-line {
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $theme-dark-primary;
      }
    }
  }
}
