@mixin reservation-timeline {
  .reservation-timeline.timeline {
    .timeline-item:last-of-type::after {
      display: none;
    }
  }
}

@include reservation-timeline;

.dark-layout {
  @include reservation-timeline;
}
