@import "@src/@core/scss/base/bootstrap-extended/include";

.entity-list {
  .content-header {
    .view-switch-container {
      .btn-group {
        width: 100%;

        button.btn {
          height: $input-height;
          width: 25%;
        }
      }
    }

    .sort-select-container {
      width: 50%;
      margin-left: auto;
      margin-bottom: 1rem;
      float: right;
      padding-left: 0.5rem;

      .btn-group {
        width: 100%;

        button.btn {
          max-width: 100%;
          height: $input-height;

          padding: 0.7rem 2rem 0.786rem 0.786rem;
          position: relative;

          &.dropdown-toggle::after {
            position: absolute;
            top: 0.85rem;
            right: 0.5rem;
            left: unset;
          }
        }
      }
    }

    .filter-dropdown-container {
      width: 50%;
      margin-right: auto;
      margin-bottom: 1rem;
      float: left;
      padding-right: 0.5rem;

      .btn-group {
        width: 100%;

        button.btn {
          max-width: 100%;
          height: $input-height;

          padding: 0.7rem 2rem 0.786rem 0.786rem;
          position: relative;

          &.dropdown-toggle::after {
            position: absolute;
            top: 0.85rem;
            right: 0.5rem;
            left: unset;
          }
        }
      }
    }
  }

  .pagination-status-container {
    clear: both;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    .content-header {
      display: flex;
      flex-direction: row-reverse;

      .view-switch-container {
        padding-left: 1rem;

        .btn-group {
          min-width: 330px;

          button.btn {
            width: auto;
            font-size: 1rem;
            padding-left: 0.8rem;
            padding-right: 0.8rem;
          }
        }
      }

      .sort-select-container {
        width: auto;
      }

      .filter-dropdown-container {
        width: auto;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    .content-header {
      .view-switch-container {
        .btn-group {
          button.btn {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
        }
      }
    }
  }
}

.ellipsis-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
