@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include"; // Bootstrap includes

.react-datepicker-popper {
  &[data-placement^="bottom"] {
    .react-datepicker__triangle {
      transform: translate(115px) !important;

      &::before,
      &::after {
        border-bottom-color: theme-color-level(primary, -1);
      }
    }
  }

  font-family: $font-family-base !important;
  z-index: 100;

  .react-datepicker__navigation-icon::before {
    margin-top: 2px;
    border-color: $white;
  }

  .react-datepicker__header {
    background-color: theme-color-level(primary, -1);

    .react-datepicker__current-month {
      font-size: 1rem;
      font-weight: 500;
      color: color-yiq(theme-color(primary));
      line-height: 14px;
    }

    .react-datepicker__day-name {
      color: color-yiq(theme-color-level(primary, -4));
    }
  }

  .react-datepicker__day {
    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected {
      background-color: theme-color-level(primary, -4);

      &:hover {
        background-color: theme-color(primary);
      }
    }

    &:hover {
      background-color: theme-color-level(primary, -11);
    }
  }
}
