@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include";

.ellipsis-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.dark-layout {
  .autocomplete-container {
    .suggestions-list {
      .suggestion-item {
        color: $light;

        &:hover:not(SVG) {
          color: $white !important;
        }

        .badge {
          transition: all 0.2s ease;
          color: $white;

          .reservation-confirmation-number-pretty {
            .text-weak {
              opacity: 0.55;
            }
          }

          &:hover {
            .reservation-confirmation-number-pretty {
              text-decoration: underline;

              .text-weak {
                opacity: 0.75;
              }
            }
          }
        }
      }
    }
  }
}
