.card.entity-card.guest-card.guest-card-placeholder {
  .card-body.guest-card-header.entity-card-header {
    .entity-card-header-toggler-wrapper {
      .entity-card-header-content {
        .entity-card-header-info {
          .entity-card-header-name {
            min-width: 30%;

            .text-row {
              display: block;
              font-size: calc(1.45 * 1.5rem);
              width: 100%;
            }
          }

          .entity-card-header-data-row-wrapper {
            .entity-card-header-data-row.ellipsis-wrapper {
              width: 100%;

              .text-row {
                width: 40%;
                font-size: calc(1.5 * 1rem);
              }
            }
          }
        }
      }
    }
  }
}
