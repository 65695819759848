.card.stats-card {
  .card-body {
    display: flex;

    .icon-section {
      .icon-container {
        display: inline-flex;
        text-align: center;
        margin: 0;

        .icon-wrapper {
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
