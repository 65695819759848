@import "@src/@core/scss/base/bootstrap-extended/include";
@import "@src/@core/scss/base/components/include";

$autocomplete-active-color: #f1f1f1;

.autocomplete-container {
  position: relative;

  .autocomplete-search {
    height: 100%;
    width: 100%;
    background-color: $white;
    &:focus {
      box-shadow: none;
    }
  }
  .suggestions-list {
    z-index: 998;
    list-style: none;
    padding-left: 0;
    padding-top: 5px;
    border-radius: 0.5rem;
    height: auto;
    max-height: calc(100vh - 8.5rem);
    width: 100%;
    position: absolute;
    margin-top: 0.5rem;
    list-style-type: none;
    background-color: $white;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);

    a {
      &:last-child {
        .suggestion-item {
          padding-bottom: 12px;
        }
      }
    }

    .suggestion-item {
      padding: 0.5rem 1rem;
      color: $body-color;
      line-height: 1 !important;

      &.suggestion-title-wrapper {
        padding: 0.5rem 1rem 0.5rem;

        .suggestion-title {
          margin-bottom: 0;
        }
      }

      a {
        color: $body-color;
      }
      &.suggestion-title {
        font-size: 0.85rem;
        color: $text-muted;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        margin-top: 0.75rem;
        margin-bottom: 0;
      }
      &.active,
      &:hover:not(.suggestion-title-wrapper):not(.no-result):not(.suggestion-item-placeholder) {
        background-color: $autocomplete-active-color;
        color: $body-color;
      }

      &:hover:not(.suggestion-title-wrapper):not(.no-result):not(.suggestion-item-placeholder) {
        cursor: pointer;
      }

      .link-view-more {
        padding: 0.9rem 1rem;
        cursor: pointer;
        display: block;
        width: 100%;
      }
    }

    &.open-up {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }
}

.dark-layout {
  .autocomplete-container {
    .suggestions-list {
      background-color: $theme-dark-card-bg;
      .suggestion-item {
        color: $theme-dark-body-color !important;
        &.suggestion-title-wrapper {
          color: $theme-dark-body-color;
        }
        &:not(.suggestion-title-wrapper) {
          &.active,
          &:hover {
            background-color: $theme-dark-body-bg !important;
          }
        }
      }
    }
  }
}
