@import "../base/bootstrap-extended/include";

.navbar-header,
.navigation-main {
  transition: background-color 0.35s !important;
}

// chattanooga
.bg-chattanooga {
  li.nav-item:not(.active) {
    .modern-nav-toggle {
      svg {
        color: $gray-200 !important;
      }
    }
    a > * {
      color: $gray-200 !important;
    }
    .badge-pill {
      color: $white !important;
    }
  }
  li {
    .badge-pill {
      box-shadow: 0px 0px 8px 0px rgba($black, 0.3) !important;
      background-color: $orange;
    }
  }
}

// san jose & nashville
.bg-san-jose,
.bg-nashville {
  li.nav-item:not(.active) {
    a > * {
      color: $gray-400 !important;
    }
    .badge-pill {
      color: $white !important;
    }
  }
  li {
    .badge-pill {
      box-shadow: 0px 0px 8px 0px rgba($black, 0.3) !important;
    }
  }
}

.main-menu {
  &.expanded {
    .collapsed-badge {
      display: none;
    }
  }
  &:not(.expanded) {
    .expanded-badge {
      display: none;
    }
    .collapsed-badge {
      right: 16px;
      top: 4px;
    }
  }
}
